class FullWidthImageContent {
	/**
	 * Create and initialise objects of this class
	 * @param {object} block
	 */
	constructor(block) {
		this.block = block;
		this.init();
	}

	init() {
		//
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.block-full-width-image-content').forEach( block => new FullWidthImageContent(block) );
});
